"use strict";

var swiper = new Swiper(".mySwiper", {
  // slidesPerView: 3,
  spaceBetween: 10,
  // freeMode: true,
  loop: true,
  breakpoints: {
    // when window width is <= 640px
    380: {
      slidesPerView: 1,
      spaceBetween: 0
    },
    575: {
      slidesPerView: 2,
      spaceBetween: 10
    },
    992: {
      slidesPerView: 3
      // spaceBetween: 30
    }
  }

});

$(function () {
  var topPos = $('.inner__list').offset().top;
  $(window).scroll(function () {
    var top = $(document).scrollTop(),
      pip = $('.consult').offset().top,
      height = $('.inner__list').outerHeight(true);
    if (top > topPos - 50 && top < pip - height) {
      $('.inner__list').addClass('fixed').removeClass('fixed-bottom');
    } else if (top > pip - height) {
      $('.inner__list').addClass('fixed-bottom').removeClass('fixed');
    } else {
      $('.inner__list').removeClass('fixed').removeClass('fixed-bottom');
    }
  });
});

// wow
new WOW().init();

// popup
$('.popup-btn').on('click', function (e) {
  e.preventDefault();

  var elementID = $(this).attr("data-element");
  $('.popup#' + elementID).toggleClass('active');
  $('.popup-overlay').toggleClass('active');
  $('#vid').attr("src", 'https://www.youtube.com/embed/fbcYOzx2Wmc');
});

$(".close-popup, .popup__ok").on("click", function (e) {
  e.preventDefault();
  $(".popup").removeClass("active");
  $('.popup-overlay').toggleClass('active');
  $(".popup").find('iframe').attr("src", "");
});

$(".popup-overlay").on("click", function (e) {
  e.preventDefault();
  $(".popup").removeClass("active");
  $('.popup-overlay').toggleClass('active');
});

$(document).keydown(function (e) {
  if (e.keyCode == 27) {
    e.preventDefault();
    $(".popup").removeClass("active");
    $('.popup-overlay').toggleClass('active');
  }
});
// tel - jquery
$(document).ready(function () {
  $("#phone").mask("+7 (999) 999-99-99");
});
// scroll to anchor
// $(document).ready(function () {
//   $(".toanchors").on("click", function (event) {
//     event.preventDefault();
//     var id = $(this).attr('href'),
//       top = $(id).offset().top - 75;
//       $('body,html').animate({
//       scrollTop: top
//     }, 500);
//   });
// });
// scroll to anchor
// $(function () {
//   $("a[href^='#about'], a[href^='#desicion'], a[href^='#first'], a[href^='#cont'], a[href^='#attention'], a[href^='#firstmeet'], a[href^='#contract']").click(function () {
//     var _href = $(this).attr("href");
//     $("html, body").animate({
//       scrollTop: $(_href).offset().top - 72 + "px"
//     });
//     return false;
//   });
// });